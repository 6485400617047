.itm-photoPicker-img-container{
   display: flex;
   align-items: center;   
   justify-content: center;
   width: 200px;
   height: 200px;
   background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAJUlEQVQoU2N8//79fwY0ICgoyIguxjgUFKI7GsTH5m4M3w1ChQDZbycHjAeyugAAAABJRU5ErkJggg==) 
}
.itm-photoPicker-img-description{
   position: absolute;
   bottom: 0;
   width: 100%;
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
   background-color: rgba(0,0,0,0.3);
   padding: 0.25rem 0.5rem;
   color: #FFF
}
.itm-photoPicker-img-decoration{
   position: absolute;
   width: 100%;
   height: 100%;
   background-color: rgba(256,256,256,0.2);
}
.itm-photoPicker-img-decoration--selected{
   border: 4px solid #448aff;
   text-align: right;
   line-height: 0;
   background: none;
}
.itm-photoPicker-img-decoration:hover{
   background: none;
}
.itm-photoPicker-img-check{
   display: inline-block;
   border:4px solid #448aff;
   border-right: 0;
   border-top: 0;
   color: #448aff;
   background-color: #FFF;
   padding: 0.125rem;
}
.itm-photoPicker-img-container--wide{
   width: 240px;
   height: 135px;
}
.itm-photoPicker-img-container--portrait{
   width: 180px;
   height: 240px;
}
.itm-photoPicker-thumbnail{
   display: flex;
   align-items: center;   
   justify-content: center;
   width: 100px;
   height: 100px;
   background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAJUlEQVQoU2N8//79fwY0ICgoyIguxjgUFKI7GsTH5m4M3w1ChQDZbycHjAeyugAAAABJRU5ErkJggg==) 
}
.itm-photoPicker-close{
   display: inline-block;
   border-right: 0;
   border-top: 0;
   color: #b72323;
   background-color: #FFF;
   padding: 0.125rem;
}
.itm-photoPicker-field-image{
   max-width: 240px;
   height: 135px;
   align-items: center;
   justify-content: center;
   display: flex;
   cursor: pointer;
   position: relative;
   color: #448AFF;
}
.bg-square{
   background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAJUlEQVQoU2N8//79fwY0ICgoyIguxjgUFKI7GsTH5m4M3w1ChQDZbycHjAeyugAAAABJRU5ErkJggg==);
}
@media (min-width: 768px){
   .itm-modal-photoSelector{
      width: 600px;
   }
}
@media (min-width: 992px){
   .itm-modal-photoSelector{
      width: 900px;
   }
}