.RichEditor-editor .RichEditor-blockquote, .itm-text-rich blockquote{
   border-left: 5px solid #eee;
   color: #666;
   font-style: italic;
   margin: 0 0 1rem;
   padding: 10px 20px;
}
 .RichEditor-editor .public-DraftStyleDefault-pre, .itm-text-rich pre {
   background-color: rgba(0, 0, 0, 0.05);
   font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
   padding: 10px;
   margin-bottom: 1rem;
}
.RichEditor-editor h1, .RichEditor-editor h2, .RichEditor-editor h3, .RichEditor-editor h4, .RichEditor-editor h5, .RichEditor-editor h6
.itm-text-rich h1, .itm-text-rich h2, .itm-text-rich h3, .itm-text-rich h4, .itm-text-rich h5, .itm-text-rich h6 {
   margin-top: 0;
   margin-bottom: .5rem;
   font-weight: 500;
   line-height: 1.2;
}
.RichEditor-editor h1, .itm-text-rich h1 {
   font-size: 2.5em;  
}
.RichEditor-editor h2, .itm-text-rich h2 {
   font-size: 2em;  
}
.RichEditor-editor h3, .itm-text-rich h3 {
   font-size: 1.75em;  
}
.RichEditor-editor h4, .itm-text-rich h4 {
   font-size: 1.5em;  
}
.RichEditor-editor h5, .itm-text-rich h5 {
   font-size: 1.25em;  
}
.RichEditor-editor h6, .itm-text-rich h6 {
   font-size: 1em;  
}
.itm-text-rich ul{
   list-style: initial;
   margin: 0 0 16px;
}
.itm-text-rich ol {
   list-style: decimal;
   margin: 0 0 16px;
}
.itm-text-rich ul li, .itm-text-rich ol li {
   margin-left: 1.5rem;
}
.itm-text-rich p {
   margin: 0;
}