.itm-social-selector-container{
   left: -183px;
   top: 2.5rem;
   width: 400px;
}
.itm-social-selector-container:before{
   content: '';
   border-style: solid;
   border-width: 7px;
   border-color: transparent transparent rgb(229, 231, 235);
   position: absolute;
   top: -14px;
   width: 0;
   height: 0;
   left: calc(50% - 10px);
}
.itm-social-selector-container:after{
   content: '';
   border-style: solid;
   border-width: 6px;
   border-color: transparent transparent #fff;
   position: absolute;
   top: -11px;
   width: 0;
   height: 0;
   left: calc(50% - 9px);
}